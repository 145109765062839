import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie"; // Import the js-cookie library

const useChatBot = (
  initialCompanyId,
  initialSystemMessage,
  use_case_id,
  surveyLink,
  testing
) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [token, setToken] = useState("dummy_token_125");
  const [companyId, setCompanyId] = useState(initialCompanyId);
  const [platformId, setPlatformId] = useState(1);
  const [userId, setUserId] = useState(0);
  const [useCaseId, setUseCaseId] = useState(use_case_id);
  const [systemMessage, setSystemMessage] = useState(initialSystemMessage);
  const [sessionId, setSessionId] = useState("");
  const [jsonError, setJsonError] = useState(null);
  const [showConfig, setShowConfig] = useState(false);
  const [showCredit, setShowCredit] = useState(false);
  const [isInputEnabled, setIsInputEnabled] = useState(true);
  const [agentName, setAgentName] = useState("");
  const [agentTitle, setAgentTitle] = useState("");
  const [userName, setUserName] = useState("");
  const [modelProvider, setModelProvider] = useState("OpenAI");
  const [modelName, setModelName] = useState("gpt-4");
  const [systemInstruction, setSystemInstruction] = useState("");
  const [variables, setVariables] = useState({});
  const [temperature, setTemperature] = useState(0);
  const [chatStatus, setChatStatus] = useState("in_progress");
  const [placeholder, setPlaceholder] = useState("Type your message");
  const [isBotStarting, setIsBotStarting] = useState(false);
  const [isAgentTyping, setIsAgentTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState({});
  const [avatarURL, setAvatarUrl] = useState("");
  const [rapid, setRapid] = useState(false);
  const [config, setConfig] = useState({});
  const [sessionInProgress, setSessionInProgress] = useState(false);
  const [sessionIDRequired, setSessionIDRequired] = useState(true);
  const [isSoundOn, setIsSoundOn] = useState(false);
  const [endpoint, setEndpoint] = useState("usecasehandler");
  const [agentResponse, setAgentResponse] = useState("");
  const [agentResponding, setAgentResponding] = useState(false);

  const backendApi = process.env.REACT_APP_API_BACKEND_URL;
  const expressURL = process.env.REACT_APP_API_EXPRESS_URL;
  const firstMessageDelay = process.env.REACT_APP_FIRST_MESSAGE_DELAY;
  const appointment_url = process.env.REACT_APP_MEETING_LINK_BASE;
  axios.defaults.headers.common["x-requested-with"] = "anyValue";

  const completedChatCookie = `completed_chat_usecase_${use_case_id}`;
  const [customPayload, setCustomPayload] = useState({});
  const TIMEOUT = 120000; // 2 minutes in milliseconds

  const speak = (text) => {
    let voices = window.speechSynthesis.getVoices();
    if (voices.length === 0) {
      window.speechSynthesis.onvoiceschanged = () => {
        voices = window.speechSynthesis.getVoices();
        performSpeech(text, voices);
      };
    } else {
      performSpeech(text, voices);
    }
  };

  const performSpeech = (text, voices) => {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = voices.find((voice) => voice.lang === "en-US"); // Customize as needed
    utterance.rate = 0.95;
    utterance.pitch = 0.95;
    window.speechSynthesis.speak(utterance);
  };

  const toggleSound = () => {
    const newSoundState = !isSoundOn;
    setIsSoundOn(newSoundState);
    Cookies.set("isSoundOn", newSoundState); // Save state in cookie
  };

  const handleSendMessage = async (
    personal_assistant = false,
    appointmentId = null,
    transcript = undefined
  ) => {
    if (!isInputEnabled) {
      return;
    }
    setAgentResponding(true);

    // For non-discrete conversations, we don't need to check for sessionId
    if (endpoint !== "non-discrete") {
      if (sessionId.length === 0 && sessionIDRequired && !personal_assistant) {
        alert(
          "Please start the conversation first or wait for the session to start"
        );
        return;
      }

      if (
        chatMessages.length === 0 &&
        sessionIDRequired &&
        !personal_assistant
      ) {
        alert("Please start the conversation first");
        return;
      }
    }

    const newMessage = {
      role: "person",
      content:
        userMessage.trim() === "" && transcript ? transcript : userMessage,
    };
    setChatMessages((prevChatMessages) => [...prevChatMessages, newMessage]);

    let payload;
    if (endpoint === "non-discrete") {
      let combined = { ...newMessage, ...JSON.parse(systemMessage) };
      payload = {
        model_provider: modelProvider,
        model_name: modelName,
        system_instruction: systemInstruction,
        user_input: newMessage.content,
        variables: variables,
        session_id: sessionId,
        user_id: userId,
        company_id: companyId,
        messages: [combined],
        platform_id: platformId,
        use_case_id: useCaseId,
        chat_type: "non-discrete",
      };
    } else {
      let combined = {};
      if (typeof systemMessage === "string") {
        combined = { ...newMessage, ...JSON.parse(systemMessage) };
        payload = {
          user_id: userId,
          company_id: companyId,
          messages: [combined],
          session_id: sessionId,
          platform_id: platformId,
          use_case_id: useCaseId,
          chat_type: "discrete",
        };
      } else {
        combined = { ...newMessage, ...systemMessage };
        payload = {
          user_id: userId,
          company_id: companyId,
          messages: [combined],
          session_id: sessionId,
          platform_id: platformId,
          use_case_id: useCaseId,
          chat_type: "discrete",
        };
      }
    }

    console.log("payload", payload);
    const combinedConfig = Object.assign({}, config, payload);

    if (appointmentId !== null) {
      combinedConfig["appointment_id"] = appointmentId;
    }

    try {
      setIsInputEnabled(false);
      setSessionInProgress(false);
      setUserMessage("");
      console.log("endpoint", endpoint);
      const response = await axios.post(
        `${backendApi}/${endpoint}`,
        combinedConfig
      );
      setSessionInProgress(true);
      setAgentName(response.data.agent_name);
      setAvatarUrl(response.data.avatar);
      setAgentTitle(response.data.agent_title);

      if (!rapid) {
        const humanMessageLengthDelay = calculateDotDelayFromUser(userMessage);
        await delay(humanMessageLengthDelay);
        setIsAgentTyping(true);
        const botDelay = calculateResponseDelayFromLLM(response.data.agentmsg);
        await delay(botDelay);
        setIsAgentTyping(false);
      }
      if (response.data.session_id && !sessionId) {
        setSessionId(response.data.session_id);
      }
      if (response.data?.additional_info?.optin === true) {
        Cookies.set("optin", "true");
      }
      if (response.data?.additional_info?.enhanced_optin === true) {
        Cookies.set("enhanced_optin", "true");
      }

      setChatMessages((prevChatMessages) => [
        ...prevChatMessages,
        { role: "assistant", content: response.data.agentmsg },
      ]);
      console.log("handleSendMessage isSoundOn: ", isSoundOn);
      if (isSoundOn) {
        speak(response.data.agentmsg);
      }
      setAgentResponse(response.data.agentmsg);
      setChatStatus(response.data.chat_status);

      if (response.data.chat_status !== "in_progress") {
        stopHeartbeat(sessionId);
        setSessionInProgress(false);
        setIsInputEnabled(false);
        if (!testing) {
          const endingMessage = `Copy this session code: ${sessionId} and return to the survey `;
          setChatMessages((prevChatMessages) => [
            ...prevChatMessages,
            { role: "assistant", content: endingMessage, link: surveyLink },
          ]);
        }
        setPlaceholder("Chat has ended");
        if (
          response.data.appointment_id !== undefined &&
          response.data.appointment_id !== null
        ) {
          const appointmentURLLink = `${appointment_url}/${response.data.appointment_id}`;
          const endingMessage = `Head over here for the meeting`;
          setChatMessages((prevChatMessages) => [
            ...prevChatMessages,
            {
              role: "assistant",
              content: endingMessage,
              link: appointmentURLLink,
            },
          ]);
        }
      } else {
        setIsInputEnabled(true);
      }
    } catch (error) {
      handleRequestError(error);
    } finally {
      setLoading(false);
    }
    setAgentResponding(false);
    setAgentResponse("");
  };

  const getTime = (type) => {
    const date = new Date();
    let hour = date.getHours();
    let amOrPm = "AM";

    if (hour >= 12) {
      amOrPm = "PM";
      if (hour > 12) {
        hour -= 12;
      }
    }

    if (type === "arrival_time") {
      return `${hour}:${String(date.getMinutes()).padStart(2, "0")} ${amOrPm}`;
    } else if (type === "scheduled_time") {
      return `${hour}:00 ${amOrPm}`;
    } else if (type === "next_shift") {
      const nextDay = new Date(date);
      nextDay.setDate(date.getDate() + 1);
      const scheduledHour = hour;

      return `${nextDay.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      })} at ${scheduledHour}:00 ${amOrPm}`;
    }
  };

  const validate = () => {
    const errors = {};

    if (!token.trim()) {
      errors.token = "Token is required.";
    }

    if (isNaN(Number(companyId))) {
      errors.companyId = "Company ID is required and must be a number.";
    }

    if (isNaN(Number(userId))) {
      errors.userId = "User ID is required and must be a number.";
    }

    if (isNaN(Number(useCaseId))) {
      errors.useCaseId = "Use case ID is required.";
    }

    if (!handleMessageChange(systemMessage)) {
      errors.systemMessage = "System Message improper format.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleMessageChange = (e) => {
    try {
      const inputValue = e;
      setHasChanges(true);

      setSystemMessage(inputValue);
      return true;
    } catch (err) {
      setJsonError("Invalid JSON format");
    }
  };

  const setSession = async (appointmentId = null) => {
    if (true) {
      if (userMessage) {
        setChatMessages([{ role: "person", content: userMessage }]);
      } else {
        setChatMessages([]);
      }
      setPlaceholder("Type your message");
      setLoading(true);
      setIsInputEnabled(false);
      let currentSystemMessage;
      if (!testing) {
        currentSystemMessage = {
          first_name: userName || "",
          streak: initialSystemMessage.streak || 10,
          scheduled_time:
            initialSystemMessage.scheduled_time || getTime("scheduled_time"),
          arrival_time:
            initialSystemMessage.arrival_time || getTime("arrival_time"),
          next_shift: initialSystemMessage.next_shift || getTime("next_shift"),
        };
        setSystemMessage(currentSystemMessage);
      } else {
        currentSystemMessage = systemMessage;
      }
      let combined = {};
      const newMessage = {
        role: "person",
        content: userMessage,
      };
      if (typeof systemMessage === "string") {
        combined = { ...newMessage, ...JSON.parse(systemMessage) };
      } else {
        combined = { ...newMessage, ...systemMessage };
      }
      console.log("currentSystemMessage", currentSystemMessage);
      let parsedSystemInputValues = parseSystemMessage(currentSystemMessage);
      console.log("parsedSystemInputValues", parsedSystemInputValues);

      setJsonError(null);
      if (combined["first_name"]) {
        setUserName(combined["first_name"]);
      } else if (
        (userName === null || userName === "") &&
        combined["first_name"]
      ) {
        setUserName(combined["first_name"]);
      } else if (userName === null || userName === "") {
        setUserName("Jimmy");
      }

      const configData = {
        company_id: companyId,
        platform_id: platformId,
        user_id: userId,
        use_case_id: useCaseId,
        messages: [combined],
      };
      let combinedConfig = Object.assign({}, config, configData);
      if (customPayload) {
        combinedConfig = Object.assign({}, combinedConfig, customPayload);
      }
      console.log("combinedConfig", combinedConfig);
      if (appointmentId !== null) {
        combinedConfig["appointment_id"] = appointmentId;
      }

      try {
        setIsBotStarting(true);
        const response = await processRequest(
          combinedConfig,
          rapid,
          firstMessageDelay
        );
        console.log("response", response);
        setSessionInProgress(true);
        if (window.smartlook) {
          if (response.data.session_id) {
            window.smartlook("track", "session_start", {
              nlpgen_session_id: response.data.session_id,
            });
          }
        }
        setLoading(false);
        setIsBotStarting(false);
        console.log("setSession isSoundOn: ", isSoundOn);
        if (isSoundOn) {
          console.log("should be speaking");
          speak(response.data.agentmsg);
        }
        if (response.data.chat_status !== "in_progress") {
          setIsInputEnabled(false);
        } else {
          setSessionId(response.data.session_id || "");
          startHeartbeat(response.data.session_id || "");
          setIsAgentTyping(true);
          setAgentName(response.data.agent_name);
          setAgentTitle(response.data.agent_title);
          setAvatarUrl(response.data.avatar);
          if (!rapid) {
            await delay(calculateResponseDelayFromLLM(response.data.agentmsg));
          }
          setIsAgentTyping(false);
          setChatMessages((prevChatMessages) => [
            ...prevChatMessages,
            { role: "assistant", content: response.data.agentmsg },
          ]);
          setHasChanges(false);
          setIsInputEnabled(true);
        }
      } catch (error) {
        setLoading(false);
        setIsBotStarting(false);
        handleRequestError(error);
      }
    }
  };

  const parseSystemMessage = (message) => {
    if (typeof message === "string") {
      try {
        return JSON.parse(message);
      } catch (error) {
        return {
          role: "person",
          content: message,
        };
      }
    }
    return message;
  };

  const sendRequest = async (data) => {
    return await axios.post(`${backendApi}/${endpoint}`, data, {
      headers: {
        "Cache-Control": "no-cache",
      },
    });
  };

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const calculateResponseDelayFromLLM = (llmResponse) => {
    const responseLength = llmResponse.length;

    if (responseLength <= 250) {
      return 2500;
    } else {
      const additionalSeconds = Math.ceil((responseLength / 250) * 15);
      return 2500 + additionalSeconds * 500;
    }
  };

  const calculateDotDelayFromUser = (userMessage) => {
    const messageLength = userMessage.length;

    if (messageLength < 50) {
      return 500;
    } else if (messageLength <= 100) {
      return 1000;
    } else {
      const additionalSeconds = Math.ceil((messageLength - 100) / 50);
      return 1000 + additionalSeconds * 500;
    }
  };

  const handleRequestError = (error) => {
    const error_message = {
      role: "assistant",
      content: "Sorry, I have an emergency. Have to go. Sorry about that.",
    };
    setChatMessages((prevChatMessages) => [...prevChatMessages, error_message]);
  };

  const timeoutPromise = (ms) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error(`Request timed out after ${ms / 1000} seconds`));
      }, ms);
    });
  };

  const processRequest = async (configData, rapid, firstMessageDelay) => {
    try {
      if (rapid) {
        return await Promise.race([
          sendRequest(configData),
          timeoutPromise(TIMEOUT),
        ]);
      } else {
        const [response] = await Promise.race([
          Promise.all([sendRequest(configData), delay(firstMessageDelay)]),
          timeoutPromise(TIMEOUT),
        ]);
        return response;
      }
    } catch (error) {
      throw error;
    }
  };

  const startHeartbeat = (sessionId) => {
    axios
      .post(`${expressURL}/start-session`, { sessionId: sessionId })
      .then((response) => {
        console.log("Session started:", response.data);
      })
      .catch((error) => {
        console.error("Error starting session:", error);
      });
  };

  const sendHeartbeat = (sessionId) => {
    axios
      .post(`${expressURL}/heartbeat`, { sessionId: sessionId })
      .then((response) => {
        console.log("Sent heartbeat", response.data);
      })
      .catch((error) => {
        console.error("Sent heartbeat", error);
      });
  };

  const stopHeartbeat = (sessionId) => {
    axios
      .post(`${expressURL}/end-session`, { sessionId: sessionId })
      .then((response) => {
        console.log("Stopped heartbeat", response.data);
      })
      .catch((error) => {
        console.error("Stopped heartbeat", error);
      });
  };

  const onSessionEnd = (sessionId) => {
    stopHeartbeat(sessionId);
    setSessionInProgress(false);

    const endSessionMessage = {
      role: "assistant",
      content:
        "Seems like you have gotten busy with something else. Talk to you later!",
    };

    const finalStatusMessage = {
      role: "system",
      content: "Agent ended session",
      finalStatus: "user_unresponsive",
    };

    setChatMessages((prevChatMessages) => [
      ...prevChatMessages,
      endSessionMessage,
      finalStatusMessage,
    ]);

    setIsInputEnabled(false);
    const updateData = {
      final_status: finalStatusMessage.finalStatus,
    };
    const apiUrl = `${backendApi}/conversation-sessions/${sessionId}/`;
    axios
      .put(apiUrl, updateData)
      .then((response) => {
        console.log("Session status updated successfully:", response.data);
      })
      .catch((error) => {
        console.error("There was an error updating the session status:", error);
      });
  };

  const getVersion = async () => {
    try {
      setIsBotStarting(true);
      const response = await axios.get(`${backendApi}/companies/${companyId}`);
      if (response?.data?.version == null) {
        setShowCredit(true);
      }
      setIsBotStarting(false);
    } catch (error) {
      setIsBotStarting(false);
      handleRequestError(error);
    }
  };

  const requestAudioAuthorization = () => {
    if (!window.speechSynthesis) {
      console.error("Speech Synthesis API not supported");
      return;
    }
    console.log("Requesting audio authorization...");
    console.log("soundOn:", isSoundOn);
    const newSoundState = !isSoundOn;
    setIsSoundOn(newSoundState);
    console.log("requestAudioAuthorization isSoundOn:", isSoundOn);
    if (!isSoundOn) {
      Cookies.set("audioAuthorized", "true", { expires: 1 }); // Save authorization for 1 day
      if (agentResponding) {
        speak(agentResponse);
      }
    } else {
      window.speechSynthesis.cancel();
    }
  };

  useEffect(() => {
    getVersion();
  }, [companyId]); // eslint-disable-line

  return {
    chatMessages,
    userMessage,
    token,
    companyId,
    platformId,
    userId,
    useCaseId,
    systemMessage,
    sessionId,
    jsonError,
    showConfig,
    showCredit,
    isInputEnabled,
    agentName,
    agentTitle,
    userName,
    chatStatus,
    placeholder,
    isBotStarting,
    isAgentTyping,
    loading,
    hasChanges,
    errors,
    avatarURL,
    completedChatCookie,
    setAvatarUrl,
    setChatMessages,
    setUserMessage,
    setToken,
    setCompanyId,
    setPlatformId,
    setUserId,
    setUseCaseId,
    setSystemMessage,
    setSessionId,
    setJsonError,
    setShowConfig,
    setIsInputEnabled,
    setAgentName,
    setUserName,
    setChatStatus,
    setPlaceholder,
    setIsBotStarting,
    setIsAgentTyping,
    setLoading,
    setHasChanges,
    handleSendMessage,
    validate,
    handleMessageChange,
    setSession,
    setRapid,
    onSessionEnd,
    sendHeartbeat,
    setSessionIDRequired,
    sessionInProgress,
    setEndpoint,
    setConfig,
    setIsSoundOn,
    isSoundOn,
    toggleSound,
    requestAudioAuthorization,
    modelProvider,
    setModelProvider,
    modelName,
    setModelName,
    systemInstruction,
    setSystemInstruction,
    variables,
    setVariables,
    customPayload,
    setCustomPayload,
    temperature,
    setTemperature,
  };
};

export default useChatBot;
